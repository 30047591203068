import * as React from "react";
import ryan from "../assets/ryan.png";
import natalie from "../assets/natalie.png";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Image = styled.img`
  animation: ${spin} infinite 3s linear;
  height: 40vmin;
`;

interface Props {}

export const Head: React.FC<Props> = () => {
  return (
    <div>
      <Image src={ryan} />
      <Image src={natalie} />
    </div>
  );
};
